.ruoli-permessi-table thead th {
  padding: 0 !important;
  z-index: 100;
  background-color: white;
  color: black;
  position: sticky;
  top: 0px;
}

.ruoli-permessi-table thead th > div {
  padding-top: 4px;
  width: 100%;
  min-height: 80px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
}
